import React from "react";
import { graphql } from "gatsby";
import moment from "moment";

import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Hero from "src/components/hero";
import Breadcrumb from "src/components/breadcrumb";

const fetch = require('isomorphic-fetch')

/**
 * Page template used for The Event Calendar WP plugin detail pages
 */
class EventDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.getEventDetails = this.getEventDetails.bind(this);
  }

  state = {
    event: undefined,
    pageInfo: []
  }

  /**
   * Request event data
   */
  componentDidMount() {
    const apiUrl = `${process.env.GATSBY_API_URL}`;
    // ORIGINAL METHOD
    // const urlParams = new URLSearchParams(window.location.search);

    // IE11 COMPATIBLE METHOD
    let urlParams = (name) => {
      let results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
      if (results == null) {
        return null;
      }
      else {
        return decodeURI(results[1]) || 0;
      }
    }


    // Check for event id parameter in URL (IE11 COMPATIBLE METHOD)
    if (urlParams('e')) {
      fetch(`${apiUrl}/wp-json/tribe/events/v1/events/${urlParams('e')}`)
        .then(response => response.json())
        .then(json => {
          if (json.code !== 'rest_invalid_param') {
            this.setState({
              event: json
            })
          }
        })
    }

    // Check for event id parameter in URL (ORIGINAL METHOD)
    // if (urlParams.has('e')) {
    //   fetch(`${apiUrl}/wp-json/tribe/events/v1/events/${urlParams.get('e')}`)
    //     .then(response => response.json())
    //     .then(json => {
    //       if (json.code !== 'rest_invalid_param') {
    //         this.setState({
    //           event: json
    //         })
    //       }
    //     })
    // }

    fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/pages?slug=calendar-of-events`)
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ pageInfo: json });
        } else {
          console.log("Data did not load");
        }
      })
  }

  /**
   * Get JSX for event details
   */
  getEventDetails() {
    const eventData = this.state.event;
    const startDate = eventData.start_date;
    const monthName = moment(startDate).format("MMM");                  // ex: Jul
    const dayOfMonth = moment(startDate).format("D");                   // ex: 3
    const dayOfMonthWithLeadingZero = moment(startDate).format("DD");   // ex: 03
    const weekDay = moment(startDate).format("ddd");                    // ex: Wed
    const startTime = moment(startDate).format("h:mm A");               // ex: 4:15 PM


    return (
      <article className="events--details gutter">
        <header className="events--details__underline gutter">
          <div className="events--details__heading">
            <p className="events--details__month">{monthName}</p>
            <h1 className="events--details__bolded">{eventData.title.replace(/&#8211;/, "-")}</h1>
            <p className="events--details__date">{dayOfMonthWithLeadingZero}</p>
            <p className="intro events--details__full--date">{weekDay}, {monthName} {dayOfMonth}, {startTime}</p>
          </div>
        </header>

        <a href="/port-info/calendar-of-events/" className="events--details__return-link gutter">{"< Return to Calendar"}</a>

        <div className="events--details__wide--text gutter">
          <div className="content content--event" dangerouslySetInnerHTML={{ __html: eventData.description }} />
        </div>
      </article>
    )
  }

  render() {
    // data for hero and breadcrumb
    const pageData = this.state.pageInfo[0];

    return (
      <React.Fragment>
        {
          pageData ?
            <Layout hasHero>
              <SEO title="Event Details" description="" />

              {(pageData.parent === 0 && pageData.acf) && (
                <Hero type="short" headingLine1="Event Details" heroBackgroundUrl={pageData.acf.hero_background} mobileHeroBackgroundUrl={pageData.acf.mobile_background} />
              )}

              <Breadcrumb categories={pageData.categories} pageTitle={pageData.title.rendered} />

              {/* Render the event details */}
              {
                this.state.event &&
                this.getEventDetails()
              }
            </Layout>
            :
            null
        }
      </React.Fragment>
    )
  }
}

export default EventDetailsPage;

// export const pageQuery = graphql`
//   query($tagName: String = "dev_only_id_calendar") {
//     wordpressPage(tagNames: { in: [$tagName] }) {
//       id
//       slug
//       title
//       wordpress_parent
//       categories {
//         name
//         slug
//       }
//       acf {
//         heading_line1
//         heading_line2
//         subheading
//         hero_background {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     }
//     site {
//       siteMetadata {
//         apiUrl
//       }
//     }
//   }
// `;
